import React, { ReactNode, useState } from 'react';
import { SessionProvider } from 'next-auth/react';
import { AppProps } from 'next/app';
import { CssBaseline } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import Notification from '@/components/Notification/Notification';
import FormProvider from '@/definitions/FormProvider';
import DefaultLayout from '@/layout/DefaultLayout';
import Theme from '@/definitions/theme/Theme';
import { useRouter } from 'next/router';
import '@/styles/globals.css';
import { EnvProvider } from '@/context/env/EnvProvider';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { appWithTranslation } from 'next-i18next';
import { PT_Sans_Narrow } from 'next/font/google';
import { it, enUS } from 'date-fns/locale';

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../../mocks');
}

export const ptSansNarrow = PT_Sans_Narrow({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

import GTM from '@/components/analytics/GTM';
import DefaultAuthLayout from '@/layout/DefaultAuthLayout';
import { mergeThemes } from '@/utils/mergeThemes';
import { PageLoader } from '@/components/core/page-loader/PageLoader';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const locales = { en: enUS, it };

function App({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();
  const session = pageProps?.session;
  const environment = pageProps?.environment;
  const disableGTM = pageProps?.disableGTM;

  function isAuthRoute(Component: ReactNode & { auth?: boolean }) {
    return (
      !router.pathname.startsWith('/login') &&
      !router.pathname.startsWith('/email-reset-password') &&
      !router.pathname.startsWith('/first-access') &&
      !router.pathname.startsWith('/reset-password')
    );
  }

  return (
    <main className={ptSansNarrow.className}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={locales[router.locale]}
      >
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={mergeThemes(environment, Theme)}>
            <CssBaseline />
            <EnvProvider initialValue={environment}>
              <PageLoader>
                <Notification />
                <SessionProvider basePath="/next/api/auth" session={session}>
                  <GTM disable={disableGTM} />
                  <FormProvider>
                    {/*// @ts-ignore*/}
                    {isAuthRoute(Component) ? (
                      <DefaultLayout>
                        <Component {...pageProps} />
                      </DefaultLayout>
                    ) : (
                      <DefaultAuthLayout>
                        <Component {...pageProps} />
                      </DefaultAuthLayout>
                    )}
                  </FormProvider>
                </SessionProvider>
              </PageLoader>
            </EnvProvider>
          </ThemeProvider>
        </CacheProvider>
      </LocalizationProvider>
    </main>
  );
}

export default appWithTranslation(App);
